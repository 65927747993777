var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "other-header-bottom" }, [
    _c("div", { staticClass: "container df jc-fs ai-c" }, [
      _c(
        "div",
        { staticClass: "other-header-bottom__select-wrap" },
        [
          _c(
            "el-select",
            {
              staticClass: "custom-component other-header-bottom__select",
              attrs: {
                placeholder: "Выберите терминал",
                disabled: _vm.terminals.length === 1,
              },
              on: { change: _vm.changeTerminal },
              model: {
                value: _vm.currentTerminal,
                callback: function ($$v) {
                  _vm.currentTerminal = $$v
                },
                expression: "currentTerminal",
              },
            },
            _vm._l(_vm.terminals, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }