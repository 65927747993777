<template>
  <div class="other-header-bottom">
    <div class="container df jc-fs ai-c">
      <div class="other-header-bottom__select-wrap">
        <el-select
          v-model="currentTerminal"
          class="custom-component other-header-bottom__select"
          placeholder="Выберите терминал"
          :disabled="terminals.length === 1"
          @change="changeTerminal"
        >
          <el-option
            v-for="item in terminals"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_TERMINAL_CURRENT_ID, SET_TERMINAL } from '@/store/actions'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ExporterHeaderBottom',
  props: {
    terminals: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentTerminal: 0,
    }
  },
  computed: {
    ...mapGetters({
      initialTerminalId: GET_TERMINAL_CURRENT_ID,
    }),
  },
  mounted() {
    this.currentTerminal = this.initialTerminalId
  },
  methods: {
    ...mapMutations({ setTerminal: SET_TERMINAL }),
    changeTerminal(value) {
      this.setTerminal(this.terminals.find(t => t.id === value))
    },
  },
}
</script>

<style lang="sass">
.other-header-bottom
  padding: $space-16
  background: $color-white
  min-height: 60px
  box-shadow: 0 4px 44px 4px rgba(0, 0, 0, 0.13)
  +to(768px)
    background: none
  .container
    +to(768px)
      justify-content: space-between
  &__icon +   &__icon
      margin-left: 20px
  &__icon,
  &__icon line
    stroke: $color-black
    cursor: pointer
    transition: stroke .3s ease
    font-size: 30px
    font-weight: bold
    path
      stroke: $color-black
      transition: stroke .3s ease
    &:hover,
    &:hover line
      stroke: $color-blue-primary
      path
        stroke: $color-blue-primary

  &__select-wrap
    margin-left: auto

  &__select
    width: 300px
    margin: 0 !important

    .el-input__icon
      line-height: 30px
</style>
